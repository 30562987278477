<template>
  <v-container
      id="dashboard-view"
      fluid
      tag="section">
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <MaterialStatsCard :data="status.usuarios_total" />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <MaterialStatsCard :data="status.inspiracoes_total" />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <MaterialStatsCard :data="status.avaliacoes_pendentes" />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <MaterialStatsCard :data="status.fornecedores_pendentes" />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <MaterialStatsCard :data="status.fornecedores_visiveis" />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <MaterialStatsCard :data="status.fornecedores_ocultos" />
        </v-col>

      </v-row>
  </v-container>
</template>

<script>
  import database from "@/services/database";
  import MaterialStatsCard from "@/components/MaterialStatsCard.vue";

  export default {
    components: {
      MaterialStatsCard
    },
    data() {
      return {
        status_numbers: null,
        status: {
          usuarios_total: {
            icon: "mdi-badge-account",
            titulo: "-",
            texto: "Usuários"
          },
          inspiracoes_total: {
            icon: "mdi-airballoon",
            titulo: "-",
            texto: "Inspirações"
          },
          fornecedores_visiveis: {
            icon: "mdi-badge-account",
            titulo: "-",
            texto: "Fornecedores Visíveis"
          },
          fornecedores_ocultos: {
            icon: "mdi-badge-account",
            titulo: "-",
            texto: "Fornecedores Ocultos"
          },
          avaliacoes_pendentes: {
            icon: "mdi-badge-account",
            titulo: "-",
            texto: "Avaliações pendentes"
          },
          fornecedores_pendentes: {
            icon: "mdi-alarm-light",
            titulo: "-",
            texto: "Fornecedores pendentes"
          }
        },
      }
    },
    mounted() {
      this.getDataFromApi();
    },
    methods: {
      getDataFromApi: async function() {
        var status_numbers = await database.getStatus();
        this.status.usuarios_total.titulo = status_numbers.usuarios.toString()
        this.status.inspiracoes_total.titulo = status_numbers.inspiracoes.toString()
        this.status.avaliacoes_pendentes.titulo = status_numbers.avaliacoes_pendentes.toString()
        this.status.fornecedores_visiveis.titulo = status_numbers.fornecedores_visiveis.toString()
        this.status.fornecedores_ocultos.titulo = status_numbers.fornecedores_ocultos.toString()
        this.status.fornecedores_pendentes.titulo = status_numbers.fornecedores_pendentes.toString()
      }
    }
  }
</script>