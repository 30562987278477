<template>
  <v-card
    elevation="4">
    <v-card-title class="align-start">
      <v-icon
        large
        left
        v-text="data.icon"
      >
      </v-icon>
      {{data.titulo}}
    </v-card-title>
    <v-card-text>
      {{data.texto}}
    </v-card-text>
  </v-card>

</template>

<script>
  export default {
    name: 'MaterialStatsCard',
    props: ["data"]
  }
</script>

<style lang="sass">
.v-card--material-stats.v-card--material .v-card--material__title
  color: #999999
  font-size: .875rem !important
  margin-left: auto
  text-align: right

.v-card__subtitle--material-stats
  color: #3C4858
</style>
